import React from 'react'
import { siteName } from '../constants/globalVars'

const OurAims = () => {
  ;(() => {
    document.title = `About Us | ${siteName}`
  })()
  return (
    <React.Fragment>
      <div className="main">
        <div className="main-inner margin-top-50">
          <div className="header-full-width">
            <h1 className="other-pages-h1">About Us</h1>
          </div>
          <div className="other-pages-image-container green-block flex">
            <div className="right-image">
              <img
                className="imagePlacer block-image"
                src="/media/images/gardening.jpg"
                alt="man gardening"
              />
            </div>
            <div className="left-text-outer">
              <div className="left-text-inner white-text">
                The charitable objectives of Glen Carne are to relieve need,
                hardship and distress of disadvantaged people in Cornwall. We
                achieve this through the provision of accommodation, support and
                training to allow our beneficiaries to live as independently as
                possible.
              </div>
            </div>
            <div className="clear-fix"></div>
          </div>

          <div className="clear-fix"></div>

          <div className="middle-two-column">
            <div className="middle-two-column-left">
              <h3>Our aims:</h3>
              <ul>
                <li>to reduce / prevent homelessness</li>
                <li>
                  to enable vulnerable people with support needs to live
                  independently within the community
                </li>
                <li>to increase life skills and educational achievement</li>
                <li>
                  to increase opportunities for learning, training and
                  employment
                </li>
                <li>to reduce social exclusion</li>
                <li>
                  to reduce unnecessary and unwanted admissions to institutional
                  care
                </li>
                <li>
                  to provide transitional support to clients moving into
                  independent accommodation.
                </li>
              </ul>
            </div>
            <div className="middle-two-column-right">
              <h3>Our objectives:</h3>
              <ul>
                <li>
                  to assist clients to take up placements into our accommodation
                </li>
                <li>
                  to meet with clients for regular keyworking and support
                  planning sessions, regular reviews and to help clients address
                  the issues identified within these meetings, with the goal of
                  achieving greater independence
                </li>
                <li>
                  to enable clients to develop skills to sustain their
                  accommodation and live in the community to the extent that the
                  support can eventually be withdrawn
                </li>
                <li>
                  to promote, advise and assist clients in the take up of
                  employment, education and training
                </li>
                <li>
                  to provide opportunities for learning, training and work
                  placements
                </li>
                <li>
                  to provide a safe environment for all clients free from
                  incidents of harassment or substance misuse
                </li>
                <li>reduce incidents of anti-social behaviour and self-harm</li>
                <li>
                  to both advocate on behalf of the client to ensure their
                  rights and easy access to services, with the goal of promoting
                  greater independence.
                </li>
              </ul>
            </div>
            <div className="clear-fix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OurAims
