import React from 'react'
import {siteName} from "../constants/globalVars"
import ModalVideo from 'react-modal-video'

export default class Tour extends React.Component {

    constructor(props) {
        super(props)
        this.title = `Take a Tour of Glen Carne | ${siteName}`
        this.state = {
            isOpen: false
          }
          this.openModal = this.openModal.bind(this)
    }
    
    openModal () {
        this.setState({isOpen: true})
      }

    render() {
        (() => {document.title = this.title;})()
        return (
            <React.Fragment>
            <div className="main">
                <div className="main-inner margin-top-50">
                <h1>Take a tour of Glen Carne</h1> 
                <h3>A video tour of Glen Carne</h3>
                <img onClick={this.openModal} className="video-placer" src="/media/images/take-a-tour-video-placer.jpg" alt="take a tour of glen carne link to Youtube video"/>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='w6MwZ83N5Lg' onClose={() => this.setState({isOpen: false})} />
                </div>    
            </div>
            </React.Fragment>
        )
    }
}