import React from 'react'
import { Link } from '@reach/router'
import { siteName } from '../constants/globalVars'

export default class OurAims extends React.Component {
  handleClick = () => {
    window.scrollTo(0, 0)
  }
  render() {
    ;(() => {
      document.title = `About Us | ${siteName}`
    })()
    return (
      <React.Fragment>
        <div className="main">
          <div className="main-inner margin-top-50">
            <div className="header-full-width">
              <h1 className="other-pages-h1">About Us</h1>
            </div>
            <div className="other-pages-image-container green-block flex">
              <div className="right-image">
                <img
                  className="imagePlacer block-image"
                  src="/media/images/gardening.jpg"
                  alt="man gardening"
                />
              </div>
              <div className="left-text-outer">
                <div className="left-text-inner white-text">
                  The charitable objectives of Glen Carne are to relieve need,
                  hardship and distress of disadvantaged people in Cornwall. We
                  achieve this through the provision of accommodation, support
                  and training to allow our beneficiaries to live as
                  independently as possible.
                </div>
              </div>
              <div className="clear-fix"></div>
            </div>
            <div className="clear-fix"></div>
            <div className="other-pages-image-container">
              <h5 className="padding-20-20-5-20">
                <p>
                  Glen Carne is a supported housing charity providing
                  accommodation to single people within Cornwall who are in need
                  of housing and support and may be suffering with complex
                  needs.
                </p>
              </h5>
              <p className="para-large padding-5-20 medium-font">
                A wide range of support services are available to clients, some
                of which include our own Tenancy Accreditation scheme, assisting
                in domestic and life skills, help with managing finances, advice
                and signposting to other services or accommodation.
              </p>
              <p className="para-large padding-5-20 medium-font">
                Glen Carne also works closely with private sector landlords to
                source move on accommodation and can help with transitional
                support into independence. Glen Carne operates an open door
                policy that assists clients with visits from other support
                services such as CPN’s, doctors, social workers etc in private
                meeting rooms.
              </p>
              
              <p className="para-large padding-5-20 medium-font">
                Over the years Glen Carne has supported hundreds of people
                through housing, support and training. See our{' '}
                <Link to={'/testimonials'} onClick={this.handleClick}>
                  Testimonials
                </Link>{' '}
                and{' '}
                <a
                  href="https://www.theonlinebookcompany.com/OnlineBooks/GlenCarne/Content/Filler"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {' '}
                  Online Book
                </a>{' '}
                for further information.
              </p>
            </div>

            <div className="middle-two-column">
              <div className="middle-two-column-left">
                <h3>Our aims:</h3>
                <ul className="medium-ul">
                  <li>to reduce / prevent homelessness</li>
                  <li>
                    to enable vulnerable people with support needs to live
                    independently within the community
                  </li>
                  <li>to increase life skills and educational achievement</li>
                  <li>
                    to increase opportunities for learning, training and
                    employment
                  </li>
                  <li>to reduce social exclusion</li>
                  <li>
                    to reduce unnecessary and unwanted admissions to
                    institutional care
                  </li>
                  <li>
                    to provide transitional support to clients moving into
                    independent accommodation.
                  </li>
                </ul>
              </div>
              <div className="middle-two-column-right">
                <h3>Our objectives:</h3>
                <ul className="medium-ul">
                  <li>
                    to assist clients to take up placements into our
                    accommodation
                  </li>
                  <li>
                    to meet with clients for regular keyworking and support
                    planning sessions, regular reviews and to help clients
                    address the issues identified within these meetings, with
                    the goal of achieving greater independence
                  </li>
                  <li>
                    to enable clients to develop skills to sustain their
                    accommodation and live in the community to the extent that
                    the support can eventually be withdrawn
                  </li>
                  <li>
                    to promote, advise and assist clients in the take up of
                    employment, education and training
                  </li>
                  <li>
                    to provide opportunities for learning, training and work
                    placements
                  </li>
                  <li>
                    to provide a safe environment for all clients free from
                    incidents of harassment or substance misuse
                  </li>
                  <li>
                    reduce incidents of anti-social behaviour and self-harm
                  </li>
                  <li>
                    to both advocate on behalf of the client to ensure their
                    rights and easy access to services, with the goal of
                    promoting greater independence.
                  </li>
                </ul>
              </div>
              <div className="clear-fix"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
