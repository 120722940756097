import React from 'react'

const vacancyAvailable = false;


function VacancyList() {
  return (
    <React.Fragment>
      <div className="links-row">
            
            
            
            <div className='links-inner-vacancies'>
              
              <p className="vacancies-title">Concierge Housing Worker</p>
              <p className="vacancies-info">
                <ul>
                <li><strong>Location:</strong> Glen Carne, Barkla Shop, St Agnes, Cornwall TR5 0XN</li>
             <li><strong>Start date:</strong> 3 rd January 2023 (or sooner if possible)</li>
             <li><strong>Salary:</strong> £21,000–23,000 per annum depending on experience</li>
             <li>1 post available (full time)</li>
             <li><strong>Hours:</strong> 37.5 hours per week, 4pm–12am Monday to Friday. Additional on-call duty when
requested (on-call supplement £105 per week pro rata).</li>
<li><strong>Position includes lone working between 6pm to midnight.</strong></li></ul></p>
<a href="/media/pdf/concierge-housing-worker.pdf" target="_blank" className="vacancies-link">
<p className='align-center'>Full job description</p></a>
            </div>



          <div className='links-inner-vacancies'>
              
              <p className="vacancies-title">Evening Security/Concierge Worker</p>
              <p className="vacancies-info">
                <ul>
                <li><strong>Location:</strong> Glen Carne, Barkla Shop, St Agnes, Cornwall TR5 0XN</li>
                <li><strong>Start date:</strong> ASAP</li>
                <li><strong>Salary:</strong> £21,575 per annum</li>
                <li>1 post available (full-time)</li>
                <li><strong>Hours:</strong> 37.5 hours per week, 4pm – 12am Monday to Friday.</li>
                <li><strong>The position includes lone working between 6pm to midnight.</strong></li></ul></p>
<a href="/media/pdf/evening-security-concierge-worker-1.pdf" target="_blank" className="vacancies-link">
<p className='align-center'>Full job description</p></a>
            </div>




            <div className='links-inner-vacancies'>
              
              <p className="vacancies-title">Evening Security/Concierge Worker</p>
              <p className="vacancies-info">
                <ul>
                <li><strong>Location:</strong> Glen Carne, Barkla Shop, St Agnes, Cornwall TR5 0XN</li>
                <li><strong>Start date:</strong> ASAP</li>
                <li><strong>Salary:</strong> £23,620 per annum</li>
                <li>1 post available (full-time)</li>
                <li><strong>Hours:</strong> 37.5 hours per week, 12am – 8am - Monday to Friday.</li>
                <li><strong>The position includes lone working between 6pm to midnight.</strong></li></ul></p>
<a href="/media/pdf/evening-security-concierge-worker-2.pdf" target="_blank" className="vacancies-link">
<p className='align-center'>Full job description</p></a>
            </div>





            
          </div>




          
          

          

          <div className="clear-fix"></div>
                
                
                <div><p className='para-large align-center margin-top-40'>If you’re interested in applying for any of the roles on offer, please send a covering letter and your CV to Andy Crozier – Support Manager - <a href='mailto:andy@glencarne.org.uk'>andy@glencarne.org.uk.</a></p></div>
    </React.Fragment>
  )
}

function NoVacancies() {
  return (<React.Fragment>
    <div className="medium-light-grey-text">
      No current staff vacancies.
    </div>
  </React.Fragment>)
}

export default function Vacancies() {
    return (
        <React.Fragment>
            <div className="main-inner-large" id="vacancies">
                <div className="clear-fix"></div>
                <div className="large-grey-text-margin-top">
                Job Opportunities
                </div>   
                {
                  vacancyAvailable ? <VacancyList /> : <NoVacancies />
                }
            </div>    
        </React.Fragment>
    )
}        