import React from 'react'
import '../css/footer.css'

import { Link } from '@reach/router'

export default class Footer extends React.Component {

  render() {
    
    const handleClick = () => {
      const mainLogo = document.getElementById('mainLogo')
      window.scollTo(0,0)
    }
    const handleClickCookiePolicy = () => {
      setTimeout(()=>{
        const position = document.getElementById('cookie-policy')
        console.log(position)
        if (position !== null) {
          window.scrollTo(0,position.offsetTop)
        }
  
      },500)
    }
    return (
      <React.Fragment>
        <div className="decorative-border-grey">
          <img
            src="/media/decorative/tools-border-green1.png"
            alt="Decorative Tools Border"
          />
        </div>
        <footer className="footer">
        <div className="normal address-footer">
        

        </div>
        <div className="address-footer-align-top">

          <div className="address-left">
            <div className="normal"><strong>t:</strong> 01872 554141/228864 (support)</div>
            <div className="normal"><strong>t:</strong> 01872 554022 (operations)</div>
            <div className="normal"><strong>e:</strong> <a className="white-text-link" href="mailto:info@glencarne.org.uk">info@glencarne.org.uk</a></div>
            <div className="normal">Barkla Shop, St. Agnes, Cornwall TR5 0XN</div>
          </div>

          <div className="address-left">
          <div className="normal"><Link to="/privacy-policy" onClick={handleClick} className="white-text-link privacy-button"><span className=" bold-font">Privacy Policy</span></Link></div><div className="normal"><Link to="/privacy-policy#cookie-policy" onClick={handleClickCookiePolicy} className="white-text-link  privacy-button"><span className=" bold-font">Cookies Policy</span></Link>
            </div>
            </div>

          <div className="address-center">
            <div className="normal">
              <a href="https://www.breathehr.com/en-gb/culture-pledge"
              target="_blank"
              rel="noopener noreferrer"
              >
                <img
                    className="breathe-badge-footer"
                    src="/media/images/Breathe_Culture_Pledge_Badge_White.png"
                    alt="Link to Breath Culture"
                    title="open Breathe Culture link in a new tab"
                  />
                </a>  
            </div>
          </div>

          </div>

          <div className="address-footer">

          <div className="address-left">
            <div className="normal">
              <a href="https://www.livingwage.org.uk/"
              target="_blank"
              rel="noopener noreferrer"
              >
                <img
                    className="footer-logos"
                    src="/media/images/LW-logo.png"
                    alt="Link to Living Wage Foundation"
                    title="open Living Wage Foundation link in a new tab"
                  />
                </a>  
            </div>
          </div>

          <div className="address-left">
            <div className="normal">
              <a href="https://www.volunteercornwall.org.uk/training-and-development/people-in-mind"
              target="_blank"
              rel="noopener noreferrer"
              >
                <img
                    className="footer-logos"
                    src="/media/images/PIM-logo.png"
                    alt="Link to People in mind"
                    title="open People in mind link in a new tab"
                  />
                </a>  
            </div>
          </div>

          <div className="address-left">
            <div className="normal">
              <a href="https://www.cornwallcommunityfoundation.com/"
              target="_blank"
              rel="noopener noreferrer"
              >
                <img
                    className="footer-logos"
                    src="/media/images/CCF_logo.png"
                    alt="Link to Cornwall Community Foundation"
                    title="open Cornwall Community Foundation link in a new tab"
                  />
                </a>  
            </div>
          </div>

          <div className="address-center">
            <a href="https://donate.giveasyoulive.com/donate?cid=136726&utm_source=charitytoolkit&utm_content=136726&utm_medium=post&utm_campaign=CTDonateButton" target="_blank"><img src="/media/images/give-as-you-live.png" width="370" alt="Donate through Give as you Live Donate" className="give-as-you-live" /></a>
          </div>
        </div>
          <div className="social-media normal">
            
            <a className="social-single" href="https://www.twitter.com/glencarne?lang=en" target="_blank" rel="no-referrer no-follow">X: @GlenCarne</a>
            <a className="social-single" href="https://www.facebook.com/Glen-Carne-Housing-Support-324876802125/" target="_blank" rel="no-referrer no-follow">Facebook: GlenCarneHousingSupport</a>
          </div>
            
        <div>
            

        </div>


          <div className="large">www.glencarne.org.uk</div>
          <div className="small">Registered charity number: 1140893</div>
          <div className="small">
            Company registered by guarantee. Company number: 7529092
          </div>
          
        </footer>
      </React.Fragment>
    )
  }
}
