import React from 'react'
import { Link } from '@reach/router'
import '../css/site-links.css'
import '../css/mega-menu.css'
// import { thisTypeAnnotation } from '@babel/types';

class InnerLinks extends React.Component {

    handleClick = () => {
        window.scrollTo(0, 0);
    }

    render() {
        const {innerLinks} = this.props
        const classDetails = `link-button link-active link-green`
        return (
            <React.Fragment>
                <div>
                    <div className="inner">
                        <div className="nav-column">
                            <ul className="header-links-ul">
                                {innerLinks.map(
                                    (innerLink,index)=> {
                                        return(
                                            <li key={index}>
                                                {(innerLink.href
                                                        &&
                                                    <Link className={classDetails} to={innerLink.href} onClick={this.handleClick}>
                                                        {
                                                            (innerLink.name 
                                                                && 
                                                            innerLink.name) 
                                                                ||
                                                            "---"
                                                        }
                                                    </Link>
                                                    )
                                                        ||
                                                    <div className="">{(innerLink.name && innerLink.name) || "---"}</div>        
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>    
                        </div>
                    </div>    
                </div>
            </React.Fragment>
        )
    }
}

class LinkInd extends React.Component {

    handleClick = () => {
        window.scrollTo(0, 0);
    }

    render() {
        const {color,href,name, innerLinks} = this.props;
        const classDetails = `link-button link-active link-${color}`
        const classDetailsInactive = `link-button link-active link-${color}`
        return (
            <React.Fragment>
                {(href
                        &&
                    <Link className={classDetails} to={href} onClick={this.handleClick} >
                    {
                        (name 
                            && 
                        name) 
                            ||
                        "---"
                    }
                    </Link>)
                        ||
                    <span style={{display:"inline-block", paddingBottom:"18px"}} className={classDetailsInactive}>{(name && name) || "---"}</span>        
                }
                { innerLinks ? <InnerLinks innerLinks={ innerLinks } /> : null }
            </React.Fragment>
        )
    }
}

class Links extends React.Component {
    constructor(props) {
        super(props)
        this.links = props.links
    }
    setUpLinks() {
        return (
            this.links.map((link,index)=> {
                return (
                    <li key={`link_${index}`}>
                        {this.links[index].type==="external" ? 
                            <a href={this.links[index].href} className={`link-button link-active link-${this.links[index].color}`} target="_blank" rel="noopener noreferrer">
                                {this.links[index].name}
                            </a>
                        : 
                        <LinkInd
                            color={this.links[index].color} 
                            name={this.links[index].name} 
                            href={this.links[index].href} 
                            innerLinks = { this.links[index].innerLinks ? this.links[index].innerLinks : null }
                            />
                        }   
                    </li>
                )
            })
        )
    }
    render() {
        return (
            <React.Fragment>
                <ul className="nav">
                    {this.setUpLinks()}
                    <li><a className='link-button link-active link-green' href='/#vacancies'>Job Opportunities</a></li>
                </ul>
            </React.Fragment>
        )
    }
}

export default class SiteLinks extends React.Component {
    constructor(props) {
        super(props)
        this.state = {toggle:false}
    }

    handleClick = () => {
        this.setState({toggle: !this.state.toggle})
    }

    render() {
        return (
            <React.Fragment> 
                <div className={this.state.toggle ? "site-links site-links-in" : "site-links site-links-out"}>
                    <div className="mobile-menu-button" onClick={this.handleClick} >
                        <div className="line">
                        </div>
                        <div className="line">
                        </div>
                        <div className="line">
                        </div>
                    </div>   
                    <div className="links-outer" onClick={this.handleClick} >
                        <Links links={this.props.links} />
                        
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
