import ImageGallery from 'react-image-gallery'
import React from 'react'
import '../../css/image-gallery.css'

export default function OurGallery() {
  const imageName = (imgNum) => {
    const first = '00000' + imgNum
    const next = first.substr(first.length - 3)
    return next
  }
  const images = []
  for (let i = 1; i <= 45; i++) {
    const k = imageName(i)
    images.push({
      id: `img_${k}`,
      original: `/media/gallery/large/gallery${k}.jpg`,
      thumbnail: `/media/gallery/thumb/gallery${k}.jpg`,
      alt: `Glen Carne Gallery image ${k}`,
    })
  }

  return (
    <React.Fragment>
      <div className="grey-block margin-base">
        <div className="main-inner">
          <div className="large-grey-text-small-margin">Our Gallery</div>
          <ImageGallery items={images} />
          <div className="clear-fix"></div>
        </div>
      </div>
    </React.Fragment>
  )
}
