import React from 'react'
import '../../css/hero.css'
import { Link } from '@reach/router'

export default function Hero() {

    const handleClick = () => {
        window.scrollTo(0, 0);
    }

    return(
        <React.Fragment>
            <div className="hero-container hero-image fade-in-image">
                
                <div className="hero-text-container-wrapper fade-in">
                    <div className="hero-text-container">
                        <div className="hero-title">
                            <h1>Glen Carne provides supported accommodation in Cornwall</h1>
                            <p>We will work with you to develop your support plan and goals to achieve during your stay. You will have a dedicated housing support keyworker who will work with you to help achieve your housing and support goals including addressing housing issues such as maintaining your tenancy and applying for Homechoice nominations, benefits, and also signposting for support on mental and physical health, staying safe, and personal empowerment.</p>
                            <p>Glen Carne operates a clean and dry support environment, therefore we are unable to work with you if you have had alcohol or drug dependence within the last six months. However, we can work with those moving on from substance misuse services as part of a planned progression once the abstinence period has been completed.</p>
                        </div>
                        </div>  
                        <div className="hero-badge-container">
                        <div className="badge badge-left script-font badge-active">
                            <Link className="badge-text" to={"/our-aims"} onClick={handleClick}>What is<br />Glen<br />Carne?</Link>
                        </div>
                        <div className="badge badge-mid script-font badge-active">
                            <Link className="badge-text" to={"/application-and-eligibility"} onClick={handleClick}>How do I<br />apply?</Link>
                        </div>
                        <div className="badge badge-right script-font badge-active">
                            <Link className="badge-text" to={"/contact-us"} onClick={handleClick}>Contact<br />Us</Link>
                        </div>    

                        </div>
                </div>
            </div>
        </React.Fragment>
    )
}