import React from 'react'
import CookieConsent from 'react-cookie-consent'
import Header from './constants/header'
import Footer from './constants/footer'
import Home from './pages/home'
import AboutUs from './pages/about-us'
import Housing from './pages/housing'
import Tour from './pages/tour'
import Eligibility from './pages/eligibility'
import Contact from './pages/contact'
import OurAims from './pages/our-aims'
import GalleryPage from './pages/gallery'
import VolunteersTraining from './pages/volunteers-and-training'
import TeamTrustees from './pages/the-team-and-trustees'
import LeisureActivities from './pages/leisure-activities'
import Support from './pages/support'
import NotFound from './pages/not-found'
import Policies from './pages/policies'
import PrivacyPolicy from './pages/privacy-policy'
import Testimonials from './pages/testimonials'
import Training from './pages/training'
import TeamBuilding from './pages/teambuilding'
import Links from './pages/links'
import { Router, Link } from '@reach/router'

import './App.css'
import './colors.css'

function App() {

  const handleClickPrivacyPolicy = () => {
    setTimeout(()=>{
      const position = document.getElementById('cookie-policy')
      console.log(position)
      if (position !== null) {
        window.scrollTo(0,position.offsetTop)
      }

    },500)
   
    
  }
  return (
    <React.Fragment>
      <Header />
      <Router primary={false}>
        <NotFound default />
        <Home path="/" />
        <AboutUs path="/about-us" />
        <Housing path="/housing" />
        <OurAims path="/our-aims" />
        <VolunteersTraining path="/volunteers-and-training" />
        <TeamTrustees path="/the-team-and-trustees" />
        <Tour path="/take-a-tour-of-glen-carne" />
        <Eligibility path="/application-and-eligibility" />
        <LeisureActivities path="/leisure-activities" />
        <GalleryPage path="/our-gallery" />
        <Contact path="/contact-us" />
        <Policies path="/policies-and-how-to-apply" />
        <PrivacyPolicy path="/privacy-policy" />
        <Support path="/support" />
        <Testimonials path="/testimonials" />
        <Training path="/training" />
        <TeamBuilding path="/team-building-and-leisure" />
        <Links path="/links-to-helpful-resources" />
      </Router>
      <Footer />
      <CookieConsent
        style={{ background: '#74aa14', fontSize: '20px' }}
        buttonStyle={{
          background: '#ffffff',
          color: '#000000',
          fontSize: '18px',
          borderRadius: '6px',
        }} 
        buttonText="Accept"
        expires={30}
      >
        <p className="align-center">We use our own and third party cookies. If you continue browsing we consider you accept the use of cookies.</p><p className="align-center"><Link to="/privacy-policy#cookie-policy" onClick={handleClickPrivacyPolicy}> <span className="white-text underline-text">Read more about our Cookies Policy.</span></Link></p>
      </CookieConsent>
    </React.Fragment>
  )
}

export default App
