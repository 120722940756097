import React from "react"
import {siteName} from "../constants/globalVars"
import ClientTestimonials from '../components/client-testimonials'


const Testimonials = () => {
    (() => {document.title = `Testimonials | ${siteName}`})()
    return (
        <div className="main">
            <div className="main-inner margin-top-50">
                <ClientTestimonials />
            </div>
        </div> 
    )        
}

export default Testimonials