import React from 'react'
import { siteName } from '../constants/globalVars'
import trusteeImgOne from '../images/PD.jpg'
import trusteeImgTwo from '../images/RC.jpg'
import trusteeImgThree from '../images/Chris-Dennis.jpg'
import trusteeImgFour from '../images/John-Douglas.jpg'
import avatar from '../images/avatar.jpg'

const trustees = [
  { name: 'Ruth Clarke (chair)', image: trusteeImgTwo, bio: '' },
  { name: 'Chris Dennis', image: trusteeImgThree, bio: '' },
  { name: 'John Douglas', image: trusteeImgFour, bio: '' },

]

const TeamTrustees = () => {
  ;(() => {
    document.title = `Team and Trustees | ${siteName}`
  })()
  return (
    <React.Fragment>
      <div className="main">
        <div className="main-inner margin-top-50">
          <div className="header-full-width">
            <h1 className="other-pages-h1">Team and Trustees</h1>
            <br />
            <div className="trustees-outer">
              {trustees.map((trustee) => (
                <div className="trustees-inner" key={trustee.name}>
                  <div className="trustees-image">
                    {
                      trustee.image ? <img src={trustee.image} alt={trustee.name} /> : null
                    }
                  </div>
                  <div className="trustees-title">{trustee.name}</div>
                  <div className="trustees-bio">{trustee.bio}</div>
                </div>
              ))}
            </div>

            <br />
            <h2 className="other-pages-h1">Founders</h2>
            <br />
          </div>
          <div className="other-pages-image-container dark-green-back">
            <div className="right-image">
              <img
                className="imagePlacer block-image"
                src="/media/images/bob-maureen-crozier.jpg"
                alt="Bob and Maureen Crozier"
              />
            </div>
            <div className="left-text-outer">
              <div className="left-text-inner white-text">
                Glen Carne started over 25 years ago by Bob and Maureen Crozier,
                who began renting rooms at Glen Carne as a private landlord.
                They decided to house those who were most needy in society, and
                began working with other homeless agencies to offer a support
                element to their accommodation.
              </div>
            </div>
            <div className="clear-fix"></div>
          </div>

          <div className="clear-fix"></div>

          <div className="middle-two-column">
            <div className="middle-two-column-left">
              Over the years Glen Carne developed to provide professional
              supported accommodation with training facilities to allow
              residents the opportunity to develop not only life skills but also
              qualifications to enable them to move on to independent
              accommodation and employment wherever possible.
            </div>
            <div className="middle-two-column-right">
              Glen Carne became a registered charity in 2011, and is run by an
              independent board of trustees continuing the vision of Bob and
              Maureen. Bob and Maureen continue to volunteer their time with the
              project and have a particular interest in the therapeutic aspect
              of Horticulture training and volunteering which have helped many
              clients address mental health issues over the years.
            </div>
            <div className="clear-fix"></div>
          </div>

          <div className="full-width margin-top-50 align-center"></div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TeamTrustees
