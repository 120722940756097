import React from 'react'
import { siteName } from '../constants/globalVars'



const PrivacyPolicy = () => {
  ;(() => {
    document.title = `Privacy Policy | ${siteName}`
  })()
  return (
    <React.Fragment>
      <div className="main">
        <div className="main-inner margin-top-50">
          <div className="header-full-width">
            <h1 className="other-pages-h1">Privacy Policy</h1>
          </div>
          

          <div className="clear-fix"></div>

          
          <div className="other-pages-image-container">
          <p className="para-large padding-5-20 large-font bold-font">
                BACKGROUND:
                </p>
              
                <p className="para-large padding-5-20 medium-font">Glen Carne (Charity) understands that your privacy is important to you and that you care about how your personal data is used and shared online. We respect and value the privacy of everyone who visits this website, www.glencarne.org.uk (“Our Site”) and will only collect and use personal data in ways that are described here, and in a manner that is consistent with Our obligations and your rights under the law. </p>
                <p className="para-large padding-5-20 medium-font">Please read this Privacy Policy carefully and ensure that you understand it. If you do not accept and agree with this Privacy Policy, you must stop using Our Site immediately. </p>
              
              <hr />


              <p className="para-large padding-5-20 large-font bold-font">
              1. Definitions and Interpretation 
              </p>

              <p className="para-large padding-5-20 medium-font">
              In this Policy the following terms shall have the following meanings:  
            </p>


            <p className="para-large padding-5-20 medium-font"><span className="bold-font">“Account” </span><br />
            means an account required to access and/or use certain areas and features of Our Site; </p>

            <p className="para-large padding-5-20 medium-font"><span className="bold-font">“Cookie”</span><br /> means a small text file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are set out in section 13, below; </p>
            
            <p className="para-large padding-5-20 medium-font"><span className="bold-font">“Cookie Law” </span><br /> means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003; </p>
            
            <p className="para-large padding-5-20 medium-font"><span className="bold-font">“personal data” </span><br /> means any and all data that relates to an identifiable person who can be directly or indirectly identified from that data. In this case, it means personal data that you give to Us via Our Site. This definition shall, where applicable, incorporate the definitions provided in the EU Regulation 2016/679 – the General Data Protection Regulation (“GDPR”); and </p>
            
            <p className="para-large padding-5-20 medium-font"><span className="bold-font">“We/Us/Our” </span><br /> means Glen Carne (Charity), a limited company registered in England under company number 7529092, whose registered address is Glen Carne, Barkla Shop, St. Agnes, Cornwall TR5 0XN.</p>

            <p className="para-large padding-5-20 large-font bold-font">2. Information About Us </p>

            <p className="para-large padding-5-20 medium-font">2.1<br />Our Site is operated by Glen Carne, a limited company registered in England under company number 7529092, whose registered address is Glen Carne, Barkla Shop, St. Agnes, Cornwall TR5 0XN. </p>
            
            <p className="para-large padding-5-20 medium-font">2.2<br /> Our Data Protection Advisor is Matthew Gavan, and can be contacted by email at data@glencarne.org.uk, by telephone on 01872 554022, or by post at Glen Carne, Barkla Shop, St. Agnes, Cornwall TR5 0XN. </p>
            
            <p className="para-large padding-5-20 medium-font">2.3<br /> We are regulated by Companies House and the Charity Commission.</p>


            <p className="para-large padding-5-20 large-font bold-font">3. What Does This Policy Cover? </p>
            
            
            <p className="para-large padding-5-20 medium-font">This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please note that We have no control over how your data is collected, stored, or used by other websites and We advise you to check the privacy policies of any such websites before providing any data to them. </p>




            <p className="para-large padding-5-20 large-font bold-font">4. Your Rights </p>
            
            
            <p className="para-large padding-5-20 medium-font">4.1<br />As a data subject, you have the following rights under the GDPR, which this Policy and Our use of personal data have been designed to uphold: </p>
            <p className="para-large padding-5-20 medium-font indent-left-small">4.1.1<br />The right to be informed about Our collection and use of personal data; </p>
            
            <p className="para-large padding-5-20 medium-font indent-left-small">4.1.2<br />The right of access to the personal data We hold about you (see section 12); </p>
            
            <p className="para-large padding-5-20 medium-font indent-left-small">4.1.3<br />The right to rectification if any personal data We hold about you is inaccurate or incomplete (please contact Us using the details in section 14); </p>
            
            <p className="para-large padding-5-20 medium-font indent-left-small">4.1.4<br />The right to be forgotten – i.e. the right to ask Us to delete any personal data We hold about you (We only hold your personal data for a limited time, as explained in section 6 but if you would like Us to delete it sooner, please contact Us using the details in section 14); </p>
            
            <p className="para-large padding-5-20 medium-font indent-left-small">4.1.5<br />The right to restrict (i.e. prevent) the processing of your personal data; </p>
            
            <p className="para-large padding-5-20 medium-font indent-left-small">4.1.6 <br />The right to data portability (obtaining a copy of your personal data to re-use with another service or organisation); </p>
            
            <p className="para-large padding-5-20 medium-font indent-left-small">4.1.7<br />The right to object to Us using your personal data for particular purposes; and </p>
            
            <p className="para-large padding-5-20 medium-font indent-left-small">4.1.8<br />Rights with respect to automated decision making and profiling. </p>
            
            <p className="para-large padding-5-20 medium-font">4.2<br />If you have any cause for complaint about Our use of your personal data, please contact Us using the details provided in section 14 and We will do Our best to solve the problem for you. If We are unable to help, you also have the right to lodge a complaint with the UK’s supervisory authority, the Information Commissioner’s Office. </p>
            
            <p className="para-large padding-5-20 medium-font">4.3<br />For further information about your rights, please contact the Information Commissioner’s Office or your local Citizens Advice Bureau. </p>



            <p className="para-large padding-5-20 large-font bold-font">5. What Data Do We Collect? </p>
            
            <p className="para-large padding-5-20 medium-font">Depending upon your use of Our Site, We may collect some or all of the following personal and non-personal data (please also see section 13 on Our use of Cookies and similar technologies):</p>
            <p className="para-large padding-5-20 medium-font">5.1<br /> name; </p>
            <p className="para-large padding-5-20 medium-font">5.2<br /> business/company name </p>
            <p className="para-large padding-5-20 medium-font">5.3<br /> job title; </p>
            <p className="para-large padding-5-20 medium-font">5.4<br /> profession; </p>
            <p className="para-large padding-5-20 medium-font">5.5<br /> contact information such as email addresses and telephone numbers; </p>
            <p className="para-large padding-5-20 medium-font">5.6<br /> IP address; </p>
            <p className="para-large padding-5-20 medium-font">5.7<br /> web browser type and version; </p>
            <p className="para-large padding-5-20 medium-font">5.8 <br />operating system. </p>




            <p className="para-large padding-5-20 large-font bold-font">6. How Do We Use Your Data? </p>
            
            
            <p className="para-large padding-5-20 medium-font">6.1<br /> All personal data is processed and stored securely, for no longer than is necessary in light of the reason(s) for which it was first collected. We will comply with Our obligations and safeguard your rights under the GDPR at all times. For more details on security see section 7, below. </p>
            
            <p className="para-large padding-5-20 medium-font">6.2<br /> Our use of your personal data will always have a lawful basis, either because it is necessary for Our performance of a contract with you, because you have consented to Our use of your personal data (e.g. by subscribing to emails), or because it is in Our legitimate interests. Specifically, We may use your data for the following purposes: </p>
            
            <p className="para-large padding-5-20 medium-font indent-left-small">6.2.1<br /> Providing and managing your access to Our Site; </p>
            <p className="para-large padding-5-20 medium-font indent-left-small">6.2.2<br /> Personalising and tailoring your experience on Our Site; </p>
            <p className="para-large padding-5-20 medium-font indent-left-small">6.2.3<br /> Supplying Our services to you (please note that We require your personal data in order to enter into a contract with you); </p>
            <p className="para-large padding-5-20 medium-font indent-left-small">6.2.4<br /> Personalising and tailoring Our services for you; </p>
            <p className="para-large padding-5-20 medium-font indent-left-small">6.2.5<br /> Replying to emails from you; </p>
            <p className="para-large padding-5-20 medium-font indent-left-small">6.2.6<br /> Supplying you with emails that you have opted into (you may unsubscribe or opt-out at any time by contacting data@glencarne.org.uk; </p>
                <p className="para-large padding-5-20 medium-font indent-left-small">6.2.7<br /> Market research; </p>
                <p className="para-large padding-5-20 medium-font indent-left-small">6.2.8 <br />Analysing your use of Our Site and gathering feedback to enable Us to continually improve Our Site and your user experience. </p>
            
                <p className="para-large padding-5-20 medium-font">6.3<br /> With your permission and/or where permitted by law, We may also use your data for marketing purposes which may include contacting you by email, telephone, text message, or post with information, news and offers on Our services. We will not, however, send you any unsolicited marketing or spam and will take all reasonable steps to ensure that We fully protect your rights and comply with Our obligations under the GDPR and the Privacy and Electronic Communications (EC Directive) Regulations 2003. </p>
            
                <p className="para-large padding-5-20 medium-font">6.4<br /> You have the right to withdraw your consent to Us using your personal data at any time, and to request that We delete it. </p>
            
                <p className="para-large padding-5-20 medium-font">6.5<br /> We do not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Data will therefore be retained for the following periods (or its retention will be determined on the following bases): </p>
            
                <p className="para-large padding-5-20 medium-font indent-left-small">6.5.1<br /> Contact information will be retained for 12 months unless otherwise requested </p>
                <p className="para-large padding-5-20 medium-font indent-left-small">6.5.2<br /> Cookie information may be stored on your computer until the browser cache is deleted. </p>


            <p className="para-large padding-5-20 large-font bold-font">7. How and Where Do We Store Your Data?</p>
            <p className="para-large padding-5-20 medium-font">7.1<br /> We only keep your personal data for as long as We need to in order to use it 4 as described above in section 6, and/or for as long as We have your permission to keep it. </p>
            <p className="para-large padding-5-20 medium-font">7.2<br /> Your data will only be stored within the European Economic Area (“the EEA”) (The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein). </p>
            <p className="para-large padding-5-20 medium-font">7.3<br /> Data security is very important to Us, and to protect your data We have taken suitable measures to safeguard and secure data collected through Our Site. </p>


             <p className="para-large padding-5-20 large-font bold-font">8. Do We Share Your Data?</p>
             <p className="para-large padding-5-20 medium-font">8.1<br /> Subject to section 8.2, We will not share any of your data with any third parties for any purposes. </p>
             <p className="para-large padding-5-20 medium-font">8.2<br /> In certain circumstances, We may be legally required to share certain data held by Us, which may include your personal data, for example, where We are involved in legal proceedings, where We are complying with legal obligations, a court order, or a governmental authority. </p>




              <p className="para-large padding-5-20 large-font bold-font">9. What Happens If Our Business Changes Hands?</p>
              
              <p className="para-large padding-5-20 medium-font">9.1<br /> We may, from time to time, expand or reduce Our business and this may involve the sale and/or the transfer of control of all or part of Our business. Any personal data that you have provided will, where it is relevant to any part of Our business that is being transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use that data only for the same purposes for which it was originally collected by Us. </p>
               
               
               <p className="para-large padding-5-20 medium-font">9.2<br /> In the event that any of your data is to be transferred in such a manner, you will be contacted in advance and informed of the changes. </p>


               <p className="para-large padding-5-20 large-font bold-font">10. How Can You Control Your Data?</p>
               
               
               <p className="para-large padding-5-20 medium-font">10.1<br /> When you submit personal data via Our Site, you may be given options to restrict Our use of your data. In particular, We aim to give you strong controls on Our use of your data for direct marketing purposes (including the ability to opt-out of receiving emails from Us which you may do by unsubscribing using the links provided in Our emails and at the point of providing your). </p>
               
               <p className="para-large padding-5-20 medium-font">10.2<br /> You may also wish to sign up to one or more of the preference services operating in the UK: The Telephone Preference Service (“the TPS”), the Corporate Telephone Preference Service (“the CTPS”), and the Mailing Preference Service (“the MPS”). These may help to prevent you receiving unsolicited marketing. Please note, however, that these services will not prevent you from receiving marketing communications that you have consented to receiving. </p>


               <p className="para-large padding-5-20 large-font bold-font">11. Your Right to Withhold Information</p>
               
               
               <p className="para-large padding-5-20 medium-font">11.1<br /> You may access Our Site without providing any data at all. However, to use all features and functions available on Our Site you may be required to submit or allow for the collection of certain data. </p>
                
               <p className="para-large padding-5-20 medium-font">11.2<br /> You may restrict Our use of Cookies. For more information, see section 13. </p>


                <p className="para-large padding-5-20 large-font bold-font">12. How Can You Access Your Data?</p>
                
                <p className="para-large padding-5-20 medium-font" >You have the right to ask for a copy of any of your personal data held by Us (where such data is held). Under the GDPR, no fee is payable and We will provide any and all information in response to your request free of charge. Please contact Us for more details at data@glencarne.org.uk, or using the contact details below in section 14. </p>



                 <p className="para-large padding-5-20 large-font bold-font" id="cookie-policy">13. Our Use of Cookies</p>
                  
                 <p className="para-large padding-5-20 medium-font">13.1<br /> Our Site may place and access certain first party Cookies on your computer or device. First party Cookies are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our services. We have carefully chosen these Cookies and have taken steps to ensure that your privacy and personal data is protected and respected at all times. </p>
                  
                  <p className="para-large padding-5-20 medium-font">13.2<br /> All Cookies used by and on Our Site are used in accordance with current Cookie Law. </p>
                  
                  <p className="para-large padding-5-20 medium-font">13.3<br /> Before Cookies are placed on your computer or device, you will be shown a pop-up requesting your consent to set those Cookies. By giving your consent to the placing of Cookies you are enabling Us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain features of Our Site may not function fully or as intended. </p>
                  
                  <p className="para-large padding-5-20 medium-font">13.4<br /> Certain features of Our Site depend on Cookies to function. Cookie Law deems these Cookies to be “strictly necessary”. These Cookies are shown below in section 13.5. Your consent will not be sought to place these Cookies, but it is still important that you are aware of them. You may still block these Cookies by changing your internet browser’s settings as detailed below in section 13.9, but please be aware that Our Site may not work properly if you do so. We have taken great care to ensure that your privacy is not at risk by allowing them. </p>
                  
                  <p className="para-large padding-5-20 medium-font">13.5<br /> The following first party Cookies may be placed on your computer or device: </p>

                  <div className="para-large padding-5-20 medium-font">
            <table className="privacy-policy-table"><tbody><tr><td className='bold-font'>Name of Cookie</td><td className='bold-font'>Purpose </td><td className='bold-font'>Strictly Necessary</td></tr>
            <tr><td>cookie_consent </td><td>To remember the visitor has consented to cookies from the website and avoid constant repetition of consent.</td><td>Yes (30 day duration period)</td></tr>
            </tbody></table>    

            </div>

            <p className="para-large padding-5-20 medium-font">13.6<br /> Our Site uses analytics services provided by Google. Website analytics refers to a set of tools used to collect and analyse anonymous usage information, enabling Us to better understand how Our Site is used. This, in turn, enables Us to improve Our Site and the services offered through it. You do not have to allow Us to use these Cookies, however whilst Our use of them does not pose any risk to your privacy or your safe use of Our Site, it does enable Us to continually improve Our Site, making it a better and more useful experience for you. </p>
            
            <p className="para-large padding-5-20 medium-font">13.7<br /> The analytics service(s) used by Our Site use(s) Cookies to gather the required information. </p>

            <p className="para-large padding-5-20 medium-font">13.8<br /> The analytics service(s) used by Our Site use(s) the following Cookies: </p>

            <div className="para-large padding-5-20 medium-font">
            <table className="privacy-policy-table">
                <tbody>
                <tr><td className='bold-font'>Name of Cookie</td><td className='bold-font'>First / Third Party</td><td className='bold-font'>Provider</td><td className='bold-font'>Purpose</td></tr>
                
                <tr><td className='bold-font'>_utmb</td><td className='bold-font'>First Party</td><td className='bold-font'>Google Visitor</td><td className='bold-font'>traffic / analysis</td></tr>
                <tr><td className='bold-font'>_utmc</td><td className='bold-font'>First Party</td><td className='bold-font'>Google Visitor</td><td className='bold-font'>traffic / analysis</td></tr>
                <tr><td className='bold-font'>_utmt</td><td className='bold-font'>First Party</td><td className='bold-font'>Google Visitor</td><td className='bold-font'>traffic / analysis</td></tr>
                <tr><td className='bold-font'>_utmt</td><td className='bold-font'>First Party</td><td className='bold-font'>Google Visitor</td><td className='bold-font'>traffic / analysis</td></tr>
                <tr><td className='bold-font'>_utmz</td><td className='bold-font'>First Party</td><td className='bold-font'>Google Visitor</td><td className='bold-font'>traffic / analysis</td></tr>
                </tbody>
            </table>
            </div>    

            <p className="para-large padding-5-20 medium-font">13.9<br /> In addition to the controls that We provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all cookies or only third party cookies. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device. </p>
            
            <p className="para-large padding-5-20 medium-font">13.10<br /> You can choose to delete Cookies on your computer or device at any time, however you may lose any information that enables you to access Our Site more quickly and efficiently including, but not limited to, login and personalisation settings. </p>
            
            <p className="para-large padding-5-20 medium-font">13.11<br /> It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings </p>



            <p className="para-large padding-5-20 large-font bold-font">14. Contacting Us</p>
            <p className="para-large padding-5-20 medium-font">If you have any questions about Our Site or this Privacy Policy, please contact Us by email at data@glencarne.org.uk, by telephone on 01872 554022, or by post at Glen Carne, Barkla Shop, St. Agnes, Cornwall TR5 0XN. Please ensure that your query is clear, particularly if it is a request for information about the data We hold about you (as under section 12, above). </p>



             <p className="para-large padding-5-20 large-font bold-font">15. Changes to Our Privacy Policy</p>
             
             <p className="para-large padding-5-20 medium-font">We may change this Privacy Policy from time to time (for example, if the law changes). Any changes will be immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your first use of Our Site following the alterations. We recommend that you check this page regularly to keep up-to-date. </p>

          
            <div className="clear-fix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PrivacyPolicy
