import React from "react"
import {siteName} from "../constants/globalVars"
import LeisureGallery from "../components/leisure-gallery"

const TeamBuilding = () => {
    (() => {document.title = `Team Building | ${siteName}`})()
    return (
        <div className="main">
            <div className="main-inner margin-top-50">


                <div className="header-full-width">
                    <h1 className="other-pages-h1">Team Building and Leisure</h1>
                </div>

                <div className="clear-fix"></div>
                <div className="other-pages-image-container">
                    <h5 className="padding-20">
                    Glen Carne encourages residents to take part in team building and leisure activities which can help combat issues of anxiety and depression, while building confidence and self-esteem. Residents have participated in team building activities at <a href="https://www.bfadventure.org/" target="_blank" rel="noopener noreferrer">BF Adventure</a>, <a href=" https://seasanctuary.org.uk/" target="_blank" rel="noopener noreferrer">Sea Sanctuary</a>, local beaches and at Glen Carne’s grounds among other locations.  
                    </h5>
                </div>

                <div className="other-pages-image-container dark-grey-block flex">
                    <div className="right-image">
                        <img className="imagePlacer block-image" src="/media/images/team-building-01.jpg" alt="team building watersports" />
                    </div>
                    <div className="left-text-outer">
                        <div className="left-text-inner white-text">
                        When the weather permits we offer a walking club to help exercise and promote mental wellbeing, taking in coastal and woodland walks around the local areas of outstanding natural beauty. We have also had our own football team for local matches, and participated in community projects, such as beach clean-up operations and sign washing projects. 
                        </div>
                    </div>
                    <div className="clear-fix"></div>
                </div>

                <div className="clear-fix"></div>
                <div className="other-pages-image-container">
                    <p className="para-large padding-20">
                    We operate a Saturday morning breakfast meeting, which is ideal for new residents to meet others and help to eat healthily. 
                    </p>
                </div>
                <div className="clear-fix"></div>
                <LeisureGallery />
            </div>
        </div>     
        )
    }

export default TeamBuilding