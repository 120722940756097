import React from 'react'
import { Link } from '@reach/router'

export default class BadgeBox extends React.Component {
    handleClick = () => {
        window.scrollTo(0,0)
    }
    render() {
        return(
            <div className="badge-box">
                <div className="badge-box-inner width-height-absolute">
                    <div className="round-badge green-back script-font">
                        <Link className="round-badge-text white-text" to={"/housing"} onClick={this.handleClick}>
                            Housing
                        </Link>
                    </div>
                </div>
                <div className="badge-box-inner">
                    <div className="badge-box-divider">
                    </div>
                </div>
                <div className="badge-box-inner width-height-absolute">
                    <div className="round-badge light-blue-back script-font">
                        <Link className="round-badge-text white-text" to={"/support"} onClick={this.handleClick}>
                            Support
                        </Link>
                    </div>
                </div>
                <div className="badge-box-inner">
                    <div className="badge-box-divider">
                    </div>
                </div>
                <div className="badge-box-inner width-height-absolute">
                    <div className="round-badge dark-blue-back script-font">
                        <Link className="round-badge-text white-text" to={"/training"} onClick={this.handleClick}>
                            Training
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}