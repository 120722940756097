import React from "react"
import {siteName} from "../constants/globalVars"

const Support = () => {
    (() => {document.title = `Support | ${siteName}`})()
    return(
        <div className="main">
            <div className="main-inner margin-top-50">

                <div className="header-full-width">
                    <h1 className="other-pages-h1">Support</h1>
                </div>


                <div className="clear-fix"></div>
                <div className="other-pages-image-container">
                    <h5 className="padding-20">
                    Glen Carne operates a clean and dry support environment, therefore we are unable to work with you if you have had alcohol or drug dependence within the last six months. However, we are able to work with those moving on from substance misuse services as part of a planned progression and the abstinence period has been completed. 
                    </h5>
                </div>


                <div className="other-pages-image-container blue-block">
                    <div className="right-image">
                        <img className="imagePlacer block-image" src="/media/support/support-artwork-MJ.jpg" alt="Art by MJ" />
                    </div>
                    <div className="left-text-outer">
                        <div className="left-text-inner white-text">
                        We will work with you to develop your own support plan and goals to achieve during, and sometimes beyond, your stay. You will have a dedicated housing support keyworker who will work with you to help achieve your goals including housing issues such as maintaining your tenancy and applying for Homechoice nominations, benefits, support for mental and physical health, staying safe, and personal empowerment.
                        <div className="small-italic-block">An original painting by MJ who is a past resident of Glen Carne. The picture shows one of our houses surrounded with flowers. The butterflies in the corners represent “new beginnings”.</div>
                        </div>
                        
                    </div>
                    <div className="clear-fix"></div>
                </div>

                <div className="clear-fix"></div>
                <div className="other-pages-image-container">
                    <p className="para-large padding-20-lr">
                    Glen Carne also works with a wide range of external agencies for specialist support, such as debt relief, professional mental health support, training etc, and provides in-house consultations from visiting support services such as CPN’s, doctors, social workers etc. in private meeting rooms. 
                    </p>
                </div>

                <div className="other-pages-image-container">
                    <p className="para-large padding-20-lr">
                    We operate our own in-house Tenancy accreditation scheme, and work closely with social and private sector landlords to source move on accommodation, and offer transitional support into independence. 
                    </p>
                </div>

                <div className="other-pages-image-container">
                    <p className="para-large padding-20-lr">
                    For many residents their goal is to either live healthy and independently, or with some level of support, and to improve their prospects for volunteering, training or employment in Cornwall. 
                    </p>
                </div>

            </div>
        </div>   
    )  
}

export default Support