import React from "react"
import {siteName} from "../constants/globalVars"
import AccommodationGallery from "../components/accommodation-gallery"

const Housing = () => {
    (() => {document.title = `Housing | ${siteName}`})()
    return (
        <div className="main">
            <div className="main-inner margin-top-50">



                <div className="header-full-width">
                        <h1 className="other-pages-h1">Housing</h1>
                    
                    </div>
                    <div className="clear-fix"></div>
                    <div className="other-pages-image-container">
                        <h5 className="padding-20">
                            Glen Carne is located in Cornwall in-between the rural villages of St. Agnes and Perranporth. Established within almost three acres of gardens, the accommodation offers an opportunity to be housed and supported in a rural and relaxing environment, with public transport links to the nearby towns of Truro and Redruth. Glen Carne operates a minibus for weekly shopping trips and also other training and social events. Cycles are available to explore the local area, including nearby beach and coastal paths. 
                        </h5>
                    </div>
                    <div className="other-pages-image-container green-block">
                        <div className="right-image">
                            <img className="imagePlacer block-image" src="/media/images/glen-carne-external.jpg" alt="housing at Glen Carne" />
                        </div>
                        <div className="left-text-outer">
                            <div className="left-text-inner white-text">
                            Our accommodation consists of single bedrooms, with most providing an en-suite shower room. We also offer a limited number of disabled bedrooms with wet rooms and wheelchair access. Communal kitchens and living spaces are available to residents, together with classrooms, lounges and superfast broadband with Wi-Fi. CCTV is in operation in shared areas and within the grounds, and evening security is in operation to ensure the safety and wellbeing of our residents.


                            </div>
                        </div>
                        <div className="clear-fix"></div>
                    </div>

                    <div className="clear-fix"></div>
                    <div className="other-pages-image-container">
                        <p className="para-large padding-20">
                        Glen Carne has a dedicated gymnasium to help mental and physical wellbeing. This is available to all current residents who have completed an in-house gym induction. 
                        </p>
                    </div>

                    <div className="clear-fix"></div>

                    <AccommodationGallery />
                    <div className="clear-fix"></div>



            </div>
        </div> 
    )        
}

export default Housing