import React from "react"

const NotFound = () => (
    <React.Fragment>
        <div className="main">
            <div className="main-inner margin-top-50">
                <h1>404 - Sorry that page is not found - More links</h1>
            </div>
        </div> 
    </React.Fragment>    
)

export default NotFound