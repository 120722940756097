import React from "react"
import {siteName} from "../constants/globalVars"

const VolunteersTraining = () => {
    (() => {document.title = `Volunteers and Training | ${siteName}`})()
    return (
        <React.Fragment>
            <div className="main">
                <div className="main-inner margin-top-50">



                    <div className="header-full-width">
                        <h1 className="other-pages-h1">Volunteers and Training</h1>
                    </div>

                    <div className="clear-fix"></div>
                    <div className="other-pages-image-container">
                        <h5 className="padding-20">
                        <p>Glen Carne offers voluntary work opportunities at our own project and also in the local community.</p>
                            <p>Working in partnership with Tempo Timecredits, Glen Carne offers a Timecredit in return for each hour volunteered, which can be redeemed at local amenities, services and our own events at Glen Carne.</p>  
                        </h5>
                    </div>


                    <img className="imagePlacer block-image" src="/media/images/timecredits.jpg" alt="time credits leaflet" />

                    <div className="clear-fix"></div>




                    
                </div>
            </div> 
        </React.Fragment>
    )
}

export default VolunteersTraining