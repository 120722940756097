import ImageGallery from 'react-image-gallery'
import React from 'react'
import '../css/image-gallery.css'

export default function LeisureGallery() {
  const imageName = (imgNum) => {
    const first = '00000' + imgNum
    const next = first.substr(first.length - 2)
    return next
  }
  const images = []
  for (let i = 3; i <= 13; i++) {
    const k = imageName(i)
    images.push({
      id: `img_${k}`,
      original: `/media/leisure/large/leisure${k}.jpg`,
      thumbnail: `/media/leisure/thumb/leisure${k}.jpg`,
      alt: `Glen Carne Gallery image ${k}`,
    })
  }

  return (
    <React.Fragment>
      <div className="grey-block margin-base">
        <div className="main-inner">
          <ImageGallery items={images} />
          <div className="clear-fix"></div>
        </div>
      </div>
    </React.Fragment>
  )
}
