import React from 'react'
import '../css/client-testimonial.css'

export default function ClientTestimonials() {
    return (
        <React.Fragment>
            <h1 style={{textAlign:"center"}}>
                 Client Testimonials
            </h1>
            <br /><br />
            <div className="button-outer">
            Visit our <a className="green-button" href="https://www.theonlinebookcompany.com/OnlineBooks/GlenCarne/Content/Casestudy1" target="_blank" rel="noopener noreferrer">Online Book</a> for more of our client testimonials and to leave your own comments.
            </div>
            <div className="white-block">
                <div className="main-inner">

                    <div className="home-container-mid overflow-hidden wheelchair-background-color">
                        <img className="absolute-image-stretch-left-right wheelchair-desktop-only" src="/media/images/testimonial-2-background.jpg" alt="testimonial number 3" />
                         <div className="client-testimonial-container">
                            <div className="client-testimonial-header-large">
                                Case Study
                            </div>
                            <div className="client-testimonial-header">
                                I fled domestic
                                violence from my
                                abusive partner
                                and her son. I had
                                suffered this abuse
                                for over 20 years.
                            </div>
                            <div className="client-testimonial-text">
                                <p>
                                    Over this time my health
                                    deteriorated, and I found myself
                                    with a diagnosis of PTSD and
                                    other mental health issues. After
                                    I left my partner I found myself
                                    homeless. Social services made a
                                    referral to Glen Carne for suitable
                                    accommodation.
                                </p>
                                <p>   
                                    Once I moved to Glen Carne I felt
                                    that I was in a safe place for me to
                                    recover and move forward with
                                    my life.
                                </p>
                                <p>
                                    Glen Carne has supported me
                                    throughout my stay, and helped
                                    to review my benefits to the point
                                    that I am now getting all the
                                    financial support I am entitled
                                    to with ESA and PIP payments.
                                    Before this my abusive partner
                                    was taking my benefits and only
                                    giving me &pound;10 per week to live
                                    on. I have now used some of
                                    my benefits for a small car, so I
                                    am now mobile and can get out
                                    on my own. This now gives me
                                    independence and freedom.
                                </p>
                                <p>
                                    Glen Carne has helped me to
                                    improve my health by linking
                                    me into the local GP surgery
                                    and hospital, and therapy with a
                                    counselling service.
                                </p>
                            </div>
                        </div>
                        <div className="clear-fix"></div>
                    </div>

                    <div className="home-container-mid overflow-hidden wheelchair-background-color">
                        <img className="absolute-image-stretch-left-right wheelchair-desktop-only" src="/media/images/testimonial-3-background.jpg" alt="testimonial number 2" />
                         <div className="client-testimonial-container">
                            <div className="client-testimonial-header-large">
                                Case Study
                             </div>
                            <div className="client-testimonial-header">
                                Before Glen Carne my life
                                had collapsed like a house
                                of cards. After losing my job
                                due to my mental health, I
                                was in debt and homeless.
                                I was at the lowest point of
                                my life and had given up on
                                all hope for the future.
                            </div>
                            <div className="client-testimonial-text">
                                <p>
                                    I was referred to Glen Carne where I
                                    received an overwhelming amount
                                    of support from empathetic staff, a
                                    beautiful place to live and feel safe –
                                    something that I had not felt for in a
                                    very long time. I was registered with a
                                    doctor, dentists, and registered for social
                                    housing, to name a few. They helped me
                                    apply for and sort my benefits out, along
                                    with all manner of other paperwork. I was
                                    linked in with the mental health service,
                                    given a mental health support worker
                                    which opened the door to therapy I had
                                    previously chased for years.
                                </p>
                                <p>   
                                    For every hurdle I came up against in
                                    rebuilding my life and essentially starting
                                    from scratch Glen Carne were there for
                                    me supporting me every step of the way.
                                </p>
                                <p>
                                    Now, I feel hopeful and excited for
                                    the future! I cannot thank everyone
                                    enough for all they have done for me. I
                                    can safely say that if were not for Glen
                                    Carne I don’t think I would still be here.
                                </p>
                            </div>
                        </div>
                        <div className="clear-fix"></div>
                    </div>

                    <div className="home-container-mid overflow-hidden wheelchair-background-color">
                        <img className="absolute-image-stretch-left-right wheelchair-desktop-only" src="/media/homepage/wheelchair-image.jpg" alt="wheelchair background" />
                         <div className="client-testimonial-container">
                            <div className="client-testimonial-header-large">
                                Case Study
                             </div>
                            <div className="client-testimonial-header">
                                I’d like to thank all the people
                                involved in the creation and
                                support of Glen Carne, I cannot
                                state strongly enough how the
                                support of this amazing place
                                literally saved my life.
                            </div>
                            <div className="client-testimonial-text">
                                <p>
                                    When I came across this charity I was at the lowest point
                                    in my life, my health physical and mental was very bad,
                                    my disability of 20 years of constant pain had become
                                    unbearable and I’d lost my home my business and my
                                    family and become homeless.
                                </p>
                                <p>   
                                    Glen Carne was a safe place with empathetic caring
                                    staff who enabled me to get good medical support help
                                    with benefits and put a roof over my head in a beautiful
                                    environment.
                                </p>
                                <p>
                                    I felt that after two years living in a van with no
                                    interaction or support I had no future at all. I was just
                                    about existing, without the support of Glen Carne I really
                                    don’t think I’d still be here.
                                </p>
                                <p>
                                    I have now started to rebuild my life and have got
                                    housing and I hope after surgery I will be able to return
                                    to work. I can’t state strongly enough that without
                                    Andy, Bob and the team with their altruistic vision many
                                    vulnerable men like myself would not be here.
                                </p>
                                <p>
                                    I can’t thank you all enough for the genuine empathy I
                                    found at Glen Carne and the support to rebuild my life.
                                </p>
                            </div>
                        </div>
                        <div className="clear-fix"></div>
                    </div>
                </div>    
            </div> 
        </React.Fragment>        
    )
}