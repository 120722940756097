import React from 'react'
import { siteName } from '../constants/globalVars'
import { Link } from '@reach/router'

export default class Eligibility extends React.Component {
  constructor(props) {
    super(props)
    this.title = `Application and Eligibility | ${siteName}`
  }

  render() {
    ;(() => {
      document.title = this.title
    })()
    return (
      <React.Fragment>
        <div className="main">
          <div className="main-inner margin-top-50">
            <h1 className="other-pages-h1">Application and Eligibility</h1>
            <h3 className="align-center">How to Apply</h3>
            <br />
            <div className="one-third-left-col green-block padding-30 black-text">
              <p className="para-large">
                Glen Carne provides support to clients with complex needs,
                typically experiencing mental health issues, homelessness or
                exclusion etc. We do not offer a night shelter facility, however
                the organisations below can offer advice or support if you are
                currently, or at risk of becoming homeless:
              </p>
              <p className="para-large">
                <span className="blue-text bold-text">
                  Cornwall Council Housing Options Team.
                </span>
                <br />
                Tel: 0300 1234161
              </p>
              <p className="para-large">
                <span className="blue-text bold-text">
                  Coastline Homeless Service, including night shelter.
                </span>
                <br />
                Freephone: 08082 027 728
                <br />
                or alternatively call 01209 200086
              </p>
              <p className="para-large">
                <span className="blue-text bold-text">
                  St Petrocs Homeless Charity, Truro.
                </span>
                <br />
                Tel: 01872 264153
              </p>
              <p className="para-large">
                <span className="blue-text bold-text">
                  Shelter has a 24 hour helpline
                </span>
                <br />
                Tel: 0808 800 4444
              </p>
              <p className="para-large">
                <Link
                  style={{
                    display: 'inline-block',
                    marginTop: '15px',
                    borderRadius: '4px',
                    padding: '8px 12px',
                    background: 'white',
                    color: '#7bb11c',
                    textDecoration: 'none',
                  }}
                  to={'/links-to-helpful-resources'}
                  onClick={this.handleClick}
                >
                  Links to further resources
                </Link>
              </p>
            </div>
            <div className="one-third-mid-col">
              <p className="para">
                Glen Carne advertises vacancies through relevant organisations
                in the local community and also operates a waiting list in which
                referrals will be held on file to be considered when a place
                becomes available.
              </p>
              <p className="para">
                Applications and referrals can be{' '}
                <a href="/media/pdf/GlenCarneApplicationForm2021.pdf" target="_blank">
                  downloaded here{' '}
                </a>
                and further information is available on our{' '}
                <a href="/policies-and-how-to-apply/">Policies page</a>.
              </p>
              <p className="para">
                The main site at St. Agnes is available to adult men
                experiencing, or are at risk of, homelessness. Glen Carne also
                works with other support services to provide longer term mixed
                sex accommodation within other areas of Cornwall.
              </p>
              <p className="para">
                All applicants must be able to demonstrate that they are
                vulnerable and would benefit from the specific support services
                provided by Glen Carne.
              </p>
              <p className="para">
                Applicants must also demonstrate a commitment to an educational,
                training or work placement. In most instances this will be
                attendance of the on-site Tenancy Accreditation course, and any
                other courses agreed as part of the support planning process. A
                Horticultural course is also available in partnership with Duchy
                College.
              </p>
            </div>
            <div className="one-third-right-col">
              <p className="para">
                Glen Carne can provide accommodation for a wheelchair user or
                disabled people.
              </p>
              <p className="para">
                Glen Carne is unable to accommodate offenders who have a spent
                or unspent offence that may suggest they could be a risk to
                other people that they share accommodation with. For example,
                offences relating to arson, serious violence or harassment of
                another person, are all likely to be reasons for exclusion.{' '}
                </p>
                <p className="para">
                <span className="bold-warning">
                  Use of alcohol or illegal drugs is not tolerated at Glen
                  Carne.
                </span> All applicants will be required to successfully pass a drug test before an offer for accommodation is made.
              </p>
              <p className="para">
                Glen Carne will assess the risk of all applicants. If an
                applicant presents too high a risk to other people or to
                themselves they will not be housed at Glen Carne, although
                alternative accommodation will be suggested wherever possible.
              </p>
              <p className="para">
                If the need for support from Glen Carne is assessed as exceeding
                our support limit then the application will not be accepted and
                alternative advice will be given wherever possible.
              </p>
            </div>
            <div className="clear-fix"></div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
