import React from "react"
import {siteName} from "../constants/globalVars"

const LeisureActivities = () => {
    (() => {document.title = `Leisure Activities | ${siteName}`})()
    return (
        <React.Fragment>
            <div className="main">
                <div className="main-inner margin-top-50">
                    <h1>Leisure Activities</h1>
                </div>
            </div>            
        </React.Fragment>   
    ) 
}

export default LeisureActivities