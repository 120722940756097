import React from 'react'
import { siteName } from '../constants/globalVars'

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.title = `Contact Us | ${siteName}`
  }

  render() {
    ;(() => {
      document.title = this.title
    })()
    return (
      <React.Fragment>
        <div className="main">
          <div className="main-inner margin-top-50">
            <div className="one-third-left-col">
              <h1>Contact us</h1>
              <p className="para">
                Glen Carne
                <br />
                Barkla Shop, St. Agnes,
                <br />
                Cornwall TR5 0XN
              </p>
              <p className="para">
                <span className="green-text">t:</span> 01872 554141/228864 (support)
                <br />
                <span className="green-text">t:</span> 01872 554022 (operations)
                <br />
                <span className="green-text">e:</span> <a className="black-text-link" href="mailto:info@glencarne.org.uk">info@glencarne.org.uk</a>
                <br />
                www.glencarne.org.uk
              </p>
              <p className="para">
                @GlenCarne
                <br />
                GlenCarneHousingSupport
              </p>
              <h4>Office Hours</h4>
              <p className="para">
                Monday–Sunday 8am–6pm.
                <br />
                Concierge/security service out-of-hours.
              </p>
              <p></p>
              <a href="https://donate.giveasyoulive.com/donate?cid=136726&utm_source=charitytoolkit&utm_content=136726&utm_medium=post&utm_campaign=CTDonateButton" target="_blank"><img src="/media/images/give-as-you-live.png" alt="Donate through Give as you Live Donate" className="give-as-you-live" /></a>
            </div>
            <div className="two-third-right-col">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10191.721045927621!2d-5.18624473950548!3d50.311893507947616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486b197cba51a32d%3A0x29f948a7c16710a4!2sGlen+Carne!5e0!3m2!1sen!2suk!4v1566415385961!5m2!1sen!2suk"
                title="Glen Carne Location Map"
                width="700"
                height="600"
                frameBorder="0"
                style={{ border: 0, maxWidth: "100%", maxHeight: "80%" }}
                allowFullScreen
              ></iframe><br/><br/>
              <a href="https://www.boilerjuice.com/community/glen-carne/" target="_blank"
                rel="noopener noreferrer" className="flex-center"><img className="max-width-100" src="/media/images/BoilerJuice.jpg" alt="Boiler Juice" /></a>
            </div>
            <div className="clear-fix"></div>
            
          </div>
        </div>
        <div className="hero-container">
        <div className="full-width-outer-contact ">

        <div className="full-width-contact">
            <a href="https://www.breathehr.com/en-gb/culture-pledge"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                  className="breathe-badge"
                  src="/media/images/Breathe_Culture_Pledge_Badge.png"
                  alt="Link to Breathe Culture"
                  title="open Breathe Culture link in a new tab"
                />
                </a>  
                <a href="https://www.homeless.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            >
                <img
                  className="homeless-link-logo"
                  src="/media/images/homeless-link-member-logo.png"
                  alt="Link to Homeless Link"
                  title="open Homeless link in a new tab"
                />
                </a> 
                <a href="https://crla.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            >
                <img
                  className="crla-logo"
                  src="/media/images/crla-logo.png"
                  alt="Link to Cornwall Residential Landlords Association"
                  title="open CRLA link in a new tab"
                />
                </a> 
                
          </div>
        <div className="full-width-contact white-block">
                <a href="https://truro.foodbank.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                  className="base-logo"
                  src="/media/images/truro-foodbank.png"
                  alt="Link to Truro Foodbank"
                  title="open Truro Foodbank link in a new tab"
                />
                </a>  
                <a href="https://fareshare.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                  className="base-logo"
                  src="/media/images/fareshare-logo.png"
                  alt="Link to Fareshare"
                  title="open Fareshare link in a new tab"
                />
                </a>  
                <a href="https://wearetempo.org/"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                  className="base-logo"
                  src="/media/images/tempo-time-credits-logo.png"
                  alt="Link to Tempo Time Credits"
                  title="open Tempo Time Credits link in a new tab"
                />
                </a>  
                <a href="https://www.nrla.org.uk/about-us"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                  className="base-logo"
                  src="/media/images/nrla-logo.png"
                  alt="Link to NRLA"
                  title="open NRLA link in a new tab"
                />
                </a>  
            </div>
            <div className="full-width-contact white-block">
            <a href="https://www.livingwage.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                  className="base-logo"
                  src="/media/images/LW-logo.png"
                  alt="Link to Living Wage Foundation"
                  title="open Living Wage Foundation link in a new tab"
                />
                </a>  
                <a href="https://www.ncvo.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
            >
              <img
                  className="base-logo"
                  src="/media/images/NCVO-logo.jpg"
                  alt="Link to NRLA"
                  title="open NRLA link in a new tab"
                />
                </a>  

                <a href="https://www.volunteercornwall.org.uk/training-and-development/people-in-mind"
            target="_blank"
            rel="noopener noreferrer"
            >
                <img
                    className="base-logo"
                    src="/media/images/PIM-logo.png"
                    alt="Link to People in mind"
                    title="open People in mind link in a new tab"
                  />
               </a>

               <a href="https://www.cornwallcommunityfoundation.com/"
            target="_blank"
            rel="noopener noreferrer"
            >
                <img
                    className="base-logo"
                    src="/media/images/CCF_logo.png"
                    alt="Link to Cornwall Community Foundation"
                    title="open Cornwall Community Foundation link in a new tab"
                  />
               </a>


            </div>
            </div>
        </div>
      </React.Fragment>
    )
  }
}
