import React from 'react'
import {siteName} from "../constants/globalVars"
import OurGallery from '../components/homepage/our-gallery'

export default class Gallery extends React.Component {

    constructor(props) {
        super(props)
        this.title = `Gallery | ${siteName}`
    }
    
    
    render() {
        (() => {document.title = this.title;})()
        return (
            <React.Fragment>
            <div id="our-gallery"></div>
                <OurGallery />
            </React.Fragment>
        )
    }
}